
/* Change this file to get your personal Portfolio */

// Your Summary And Greeting Section

import emoji from "react-easy-emoji";

const greeting = {
  /* Your Summary And Greeting Section */
  username: "Achal Jhawar",
  title: "Hi all, I'm Achal",
  subTitle: emoji("I am 14 and a Certified Google developer. I develop meaningful actions on Google Assistant to provide an interactive experience with users worldwide ."),
};

// Your Social Media Link

const socialMediaLinks = {

  github: "https://github.com/achaljhawar",
  linkedin: "https://www.linkedin.com/in/achal-jhawar-12b4b71a6/",
  gmail: "achaldps@gmail.com",
  gitlab: "https://gitlab.com/achaljhawar",
  facebook: "https://www.facebook.com/achal.jhawar.96/",
  instagram: "https://www.instagram.com/achaldwx/",
  twitter: "https://twitter.com/AchalJhawar"
};

// Your Skills Section

const skillsSection = {
  title: "What i do",
  subTitle: "CRAZY CHATBOT WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji("⚡ develop meaningful actions on Google Assistant to provide an interactive experience with users worldwide"),
    emoji("⚡ Integration of third party services such as Firebase/ AWS / Azure")
  ],

/* Make Sure You include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Web Designer",  //Insert stack or technology you have experience in
      progressPercentage: "75%"  //Insert relative proficiency in percentage
    },
    {
      Stack: "Chatbot Development",
      progressPercentage: "65%"
    },
    {
      Stack: "Programming",
      progressPercentage: "55%"
    }
  ]
};


// Your top 3 work experiences

const workExperiences = {
  viewExperiences: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Google Action Devloper",   
      company: "Google",
      companylogo: require("./assets/images/google.jpg"),
      date: "April 2017 –",
      desc: "I develop meaningful actions on Google Assistant to provide an interactive experience with users worldwide ."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "achaljhawar", // Change to your github username to view your profile in Contact Section.
  showGithubProfile :"true" // Set true or false to show Contact profile using Github, defaults to false 
};


// Some Big Projects You have worked with your company

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME GOOGLE ASSISTANT ACTIONS WHICH ARE QUIET POPULAR ON ACTIONS DIRECTORY",
  projects: [
    {
      image: require("./assets/images/History Of India.jpg"),
      link: "http://bit.ly/2JLZfIB"
    },
    {
      image: require("./assets/images/Simpsons Wiki.jpg"),
      link: "https://bit.ly/2XMnvlg"
    }
  ]
};

// Your Achievement Section Include Your Certification Talks and More

const achievementSection = {

  title: emoji("Achievements And Certifications 🏆 "),
  subtitle: "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achivementsCards: [
     {
      title: "Localising My Google Assistant Action",
      subtitle: "Developed a Google Assistant Action History of India that is available on 2 Billion devices world wide in 2 languages",
      image: "https://achaljhawar.netlify.app/assets/project.6da7afbc358ecfc8675b6d6920adf00b.jpg",
      footerLink: [{ name: "View Google Assistant Action", url: "https://assistant.google.com/services/a/uid/0000003732efd0b7?hl=en" }]
    }
  ]
};

// Blogs Section

const blogSection = {

  title: "Blogs",
  subtitle: "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url: "https://medium.com/@achaldps/how-to-build-a-google-assistant-action-on-dialogflow-779cd5df234a",
      title: "How to build a Google Assistant Action on Dialogflow",
      description: "A Google Assistant Action with DialogFlow"
    }
  ]
};

// Talks Sections

const talkSection = {
  title: "MEETUPS",
  subtitle: emoji("I LOVE TO EXTEND MY LIMIT OF GAINING KNOWLEDGE  😅"),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Indore 2019",
      event_url: "https://devfest19indore.firebaseapp.com/",
      image:
        "https://scontent.fkhi6-1.fna.fbcdn.net/v/t1.0-9/76714032_1730516240415559_1293494289556307968_o.jpg?_nc_cat=103&_nc_ohc=s5f81rdZd6wAQmqUa52YQBA21MjVOy7e-HN9jI7MspRDC-v5ahEouyInQ&_nc_ht=scontent.fkhi6-1.fna&oh=c29141a9a0de7ff2011a8191f5475a78&oe=5E83127C"
    }
  ]
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO KNOW ABOUT TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: ["https://podcasts.google.com/feed/aHR0cHM6Ly93d3cubnByLm9yZy9yc3MvcG9kY2FzdC5waHA_aWQ9NTEwMzEy/episode/NmQyZmM3NWYtNGYyOC00YTUxLWFmMWYtNmZlZGJhY2Q2OWQz?ved=0CAcQ38oDahcKEwiYgInG__bpAhUAAAAAHQAAAAAQEQ&hl=en-IN"]
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle: "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91-9977144991",
  email_address: "achaldps@gmail.com"
};

//Twitter Section

const twitterDetails = {

  userName : "AchalJhawar"//Replace "twitter" with your twitter username without @
  
};
export { greeting, socialMediaLinks, skillsSection, techStack, workExperiences, openSource, bigProjects, achievementSection, blogSection, talkSection, podcastSection, contactInfo , twitterDetails};
